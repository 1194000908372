import React, { useEffect } from 'react';
import { Footer, Navigation, ContentSlider, TariffBlock, GainAccess } from '@libs/components';
import { Header } from '@modules/PriceCenter/Header';
import { ProductCapabilities } from '@modules/PriceCenter/ProductCapabilities';
import { contentSliderList } from '@modules/PriceCenter/data';
import { type RefObjectType } from '@libs/types';
import { addMetaTag, removeMetaTag } from '@libs/components/pageBlocks/TariffBlock/utils/utils';

export const PriceCenter = () => {
    const refs: RefObjectType<HTMLDivElement> = {};

    const setChildRefs = (childRefs: RefObjectType<HTMLDivElement>) => {
        Object.assign(refs, childRefs);
    };

    useEffect(() => {
        addMetaTag('title', 'Ценовой центр - сервис по анализу и оценке стоимости ценных бумаг');
        addMetaTag(
            'description',
            'Сервис “Ценовой центр” — надежный инструмент для оценки стоимости и риска ценных бумаг. Пользуйтесь данными НРД для определения справедливой стоимости активов, включая ликвидные и неликвидные сегменты рынка, для обоснованных инвестиционных решений.',
        );

        return () => {
            removeMetaTag('title');
            removeMetaTag('description');
        };
    }, []);

    return (
        <>
            <Navigation />
            <Header refs={refs} />
            <ProductCapabilities />
            <ContentSlider list={contentSliderList} />
            {/*<TariffBlock setRefs={setChildRefs} productGuid={process.env.REACT_APP_PRICECENTER_GUID}/>*/}
            <GainAccess setRefs={setChildRefs} sendEmail={false} productGuid={process.env.REACT_APP_PRICECENTER_GUID} />
            <Footer />
        </>
    );
};
