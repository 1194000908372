import React, { useEffect } from 'react';
import { Feedback, Footer, Navigation } from '@libs/components';
import { addMetaTag, removeMetaTag } from '@libs/components/pageBlocks/TariffBlock/utils/utils';

import { Header } from './components/Header';
import { Products } from './components/Products';

export const ProductsPage = () => {
    useEffect(() => {
        addMetaTag('title', 'Программы для инвестиционного портфеля: сервисы аналитики и учета от Datashop');
        addMetaTag(
            'description',
            'Онлайн-сервисы по учету инвестиций и инструменты аналитики для инвесторов. Бесплатные данные для физических и юридических лиц.  Создавайте, анализируйте и управляйте структурой вашего инвестиционного портфеля, используя актуальные данные рынка.',
        );

        return () => {
            removeMetaTag('title');
            removeMetaTag('description');
        };
    }, []);

    return (
        <>
            <Navigation />
            <Header />
            <Products />
            <Feedback />
            <Footer />
        </>
    );
};
